input[type="radio"] {
  width: 17px !important;
  height: 17px !important;
}

.loader-text {
  padding-top: 50px;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  color: #ff5700;
}

.loader_wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.5);
}

.lds-roller {
  display: inline-block;
  width: 64px;
  position: absolute;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ff5700;
  margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

._3uApM {
  display: none !important;
}
._2JvrO {
  background: #ff5700 !important;
}
._2kL0S {
  background: #ff5700 !important;
}
/* ._2Jtxm {
  background-color: #e7eaf3;
} */

._53Ji7 {
  padding: 0rem !important;
}
.powredby {
  text-align: center;
}

.custom-inpt {
  padding: 10px !important;
}

.css-13cymwt-control {
  z-index: 0 !important;
}
/* text input after */

.react-datepicker__current-month,
.react-datepicker__navigation--next {
  display: none;
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.react-datepicker .react-datepicker__header {
  border: 0;
  font-weight: 500;
}
.react-datepicker__month-read-view--selected-month {
  margin-right: 24px;
}
.react-datepicker__month-select,
.react-datepicker__year-select {
  border-radius: 5px;
  border: none;
}

.react-datepicker__year-select {
  border-radius: 5px !important;
  border: none !important;
}

.react-datepicker__month-read-view--down-arrow {
  font-weight: 300 !important;
  margin-right: 22px !important;
  margin-top: 1px;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #ff5700 !important;
  color: #fff;
}
.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #ff5700 !important;
  color: #fff;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none !important;
  color: black;
}