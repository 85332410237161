* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 34px;
}
body {
  font-family: "Roboto", sans-serif;
}
.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}
.mob-div {
  background-color: #e8e9ed;
  height: 100%;
  width: 100vw;
  @media (min-width: 1650px) {
    background-color: #e8e9ed;
    height: auto;
  }
  img {
    margin-bottom: 22px;
  }
  .mob-div-inner {
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    border-radius: 20px;
    padding: 43px 20px;
    background-color: #f4f5f7;
    box-shadow: -10px -10px 25px 0px #ffffff, 10px 10px 25px 0px #0000001a;
    overflow: hidden;
  }
  h3 {
    font-size: 25px;
    font-weight: 800;
    color: #152745;
    padding-bottom: 39px;
  }
}

.button-wrapper {
  text-align: center;
  margin: 30px 0px 10px;
  button {
    width: 245px;
    padding: 10px 27px;
    border: 1px solid #ff5700;
    position: relative;
    overflow: hidden;
    background-color: #ff5700;
    text-align: center;
    text-transform: capitalize;
    font-size: 20px;
    transition: 0.3s;
    font-family: inherit;
    color: #fff;
    cursor: pointer;
    border-radius: 45px;
    // letter-spacing: 2px;
    font-family: "Roboto", sans-serif;
  }
  .white_btn {
    border: 1px solid #fff;
    background-color: #fff;
    color: #000;
  }
}
.form-input-wrapper {
  padding-bottom: 22px;

  label {
    display: block;
    padding-left: 15px !important;
    font-size: 16px;
    font-weight: 500;
    color: #152745;
    padding-bottom: 5px;
  }
  input,
  select {
    display: flex;
    padding: 20px 25px;

    font-size: 14px;
    font-weight: 400;
    border: none;
    border-radius: 43px;
    width: 100%;
    color:#000!important;

    accent-color: #ff5700;
  }
  select {
    option {
      &:first-child {
        color: rgba(21, 39, 69, 0.5) !important;
      }
    }
  }
}

.css-13cymwt-control {
  display: flex;
  padding: 10px 25px;

  font-size: 14px;
  font-weight: 400;
  border: none;
  border-radius: 43px !important;
  border: none !important;
  width: 100%;

  accent-color: #ff5700;
}
.css-13cymwt-control:on .powredby {
  text-align: center;
  padding-top: 42px;
  .scl {
    height: 18px !important;
    width: 13px !important;
    margin-bottom: 0 !important;
  }
}
.form-input-wrapper select {
  /* for Firefox */
  appearance: none;
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1.5em + 2px),
    calc(100% - 15px) calc(1.5em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 4px, 0px 0em;
  background-repeat: no-repeat;
}

.form-input-wrapper select::-ms-expand {
  display: none;
}

.final_step {
  text-align: center;

  p {
    font-size: 18px;
    font-weight: 400;
    color: #152745;
    padding-bottom: 14px;
  }
}
.scl {
  margin: 0px 0px !important;
  height: auto !important;
  width: auto !important;
}
.white_btn:hover {
  background-color: #ff5700;
  color: #fff;
}

.select_css select {
  border: 0;
  z-index: 1111;
  position: absolute;
  font-size: 11px;
  margin-top: -4px;
  overflow-y: auto;
  overflow-x: wrap;
  width: 267px !important;

  box-shadow: 0 4px 4px #ddd;
}

.select_css select option {
  padding: 5px 15px;
  white-space: normal;
}

.select_css {
  margin-top: 5px;
  margin-right: 0;
  margin-left: 0;
  width: 500px;
  height: 34px;
  border-radius: 3px;
  overflow: hidden;
}
.css-13cymwt-control {
  z-index: 99999999;
}
.css-13cymwt-control::after {
  z-index: 999999;
}

.final_img {
  height: 181px !important;
}

.justify-content {
  justify-content: space-evenly;
}